// src/utils/validation.js

export const validateEmail = (email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };
  
  export const validatePassword = (password) => {
    if (password.length < 6) {
      return 'Password must be at least 6 characters long';
    }
    return '';
  };

  export const resetPassword=({password,confirmPassword})=>{
    const errors = {};
    const isValidPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password);
    const isMatchingPassword = password == confirmPassword;
    if(!isValidPassword){
// return "Password must be at least 8 characters long and include at least one number and one symbol"
errors.password="Password must be at least 8 characters long and include at least one number and one symbol"
    }

    if(!isMatchingPassword){
      // return "Passwords do not match"
      errors.confirmPassword="Passwords do not match"
    }
return errors

  }
  



  export const validateOtp = (otp) => {
    if (otp.length !== 6 || !/^\d*$/.test(otp)) {
      return 'OTP must be 6 digits';
    }
    return '';
  };
  
  export const validateForm = ({ email, password }) => {
    const errors = {};
  
    const emailError = validateEmail(email);
    if (emailError) {
      errors.email = emailError;
    }
  
    const passwordError = validatePassword(password);
    if (passwordError) {
      errors.password = passwordError;
    }
  
    return errors;
  };


  
  export const validateFileUpload = (selectedClients,files) => {
    const errors = {};
  
    if (!selectedClients) {
      errors.client = 'Please select a client ';
    } 
    
    if (files.length === 0) {
      errors.files  = 'Please select at least one file.';
    }
    
    
 
  
    return errors;
  };








  