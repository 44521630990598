
const messages = {
    loading: "Please wait...",
    dataNotFound: "No data found.",

    error: {
      INVALID_EMAIL: 'Please enter a valid email address.',
       INVALID_PASSWORD: 'Password must be at least 6 characters long.',
       RESET_INVALID_PASSWORD: 'Password must be at least 8 characters long and include at least one number and one symbol.',
       RESET_INVALID_CONFIRM_PASSWORD: 'Passwords do not match.',
       INVALID_OTP: 'OTP must be 6 digits.',
       COMPOSE_NEW_MESSAGE:""
    },
    success: {
        LOGIN_SUCCESSFUL: "Login successful, redirecting...",
        PASSWORD_RESET_SUCCESS: "Password updated successfully",
        
    }
};
  
  export default messages;
  