import React, { useState } from 'react'
import { Table, InputGroup, FormControl, Badge } from 'react-bootstrap';
import { getFileIcon } from '../../utils/fileIcon';
import { trashIcon } from '../../utils/icons';
import PreviewFileModal from '../../components/common/Filemanager_PreviewFileModal';
import { downloadFile } from '../../utils/downloadFiles';
import FilesService from '../../services/files.service';
import Pagination from '../../components/common/Pagination';
const Documents_files = ({ files, handleDelete, currentPage, TotalPage, currentSet, setCurrentSet, setcurrentPage, getFiles,setSortDirection,setSortBy,sortBy,sortDirection }) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [approved, setapproved] = useState(false)
  const [approvedShow, setapprovedShow] = useState(false)
  const [FileId, setFileId] = useState("")
  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);
  const downloadExtensions = ['docx', 'xlsx', 'pptx'];

  // const handleShow = (file) => {


  //   if (downloadExtensions.includes(file.file_type)) {

  //     downloadFile(file.file_url, file.file_name);
  //   } else {

  //     setShow(true);
  //     setSelectedFileUrl(file.file_url);
  //   }
  // };


  const handleShow=async(file)=>{
    try{
    const res= await FilesService.filePreview(file.id)
      
        if (res) {
        
        const blob = res.data;
        const blobUrl=URL.createObjectURL(blob)
        setFileBlob(blobUrl); 
        setFileType(res.headers['content-type'])
        if (downloadExtensions.includes(file.file_type) &&blobUrl ){
         downloadFile(blobUrl, file.file_name);
         }else{
        setShow(true);
      }
      } else {
        console.error('Failed to fetch file');
      }
    }catch(err){
      setFileBlob(null)
      setShow(true);
      console.log(err)
    
    }
    
    
    
    
    }


  const uploadViewhandleShow =async (files) => {
    if (files.status == "approved" || files.status == "rejected") {
      setapproved(false)
    } else {
      setapproved(true)
    }



    setFileId(files.id)
    setapprovedShow(true)
    setShow(true);

    const res= await FilesService.filePreview(files.id)
 if (res) {
  const blob = res.data;
    const blobUrl=URL.createObjectURL(blob)
    setFileBlob(blobUrl); 
    setFileType(res.headers['content-type'])
  }
}



  const handleapprovedFile = async (status) => {
    const data = {
      status: status
    }
    try {
      await FilesService.approvedFile(FileId, data)
      // setShow(false)
      getFiles()
    } catch (err) {
      console.log(err)
    }
  }

  const ucfirst = (str) => str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : "";

  const handleSort = (column) => {
    const newDirection = sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
    
  };

  return (
    <div className='documents_files'>
      <div className='table-responsive' >
        <Table hover="true" responsive="true" >
          <thead>
            <tr >
              <th onClick={() => handleSort("file_name")}>Name {sortBy === "file_name" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
              <th>Corporation</th>

              <th onClick={() => handleSort("uploaded_by")}>Uploaded  by  {sortBy === "uploaded_by" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
              <th onClick={() => handleSort("created_at")}>Uploaded on  {sortBy === "created_at" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
              <th onClick={() => handleSort("approval_status")}>Status {sortBy === "approval_status" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
              <th className='text-center'>Trash</th>

            </tr>
          </thead>
          <tbody>


            {files && files.map((files) => (
              <tr>
                <td className="" >
                  <div className='d-flex align-items-center'>
                { files.file_type && (<span className='icon'><img src={getFileIcon(files.file_type)} className="file-icon me-2" /></span>)} 
                  <span role="button" onClick={() => handleShow(files)}>{files.file_name}</span> 
                  </div>
                </td>
                <td>{files.clientname}</td>
                <td>{files.uploaded_by}</td>


                <td>{files.uploaded_on}</td>
                <td>
                  {files.status == "pending" ? (

                    <Badge  role="button"  pill bg='dark' onClick={() => uploadViewhandleShow(files)} >View & Approve</Badge>



                  ) : files.showstatus ? (<span
                    className={`badge rounded-pill ${files.status.toLowerCase() === 'approved' ? 'bg-success' : 'bg-secondary'}`}
                  >
                    {ucfirst(files.status)}
                  </span>) : ("")}

                </td>
                <td  className='text-center'>
                  {files.action &&
                    <div>
                      <img role='button' src={trashIcon} alt="folder icon" onClick={() => handleDelete(files.id)} />
                    </div>}


                </td>
              </tr>
            ))}



          </tbody>
        </Table>
       <div className='d-flex justify-content-end w-100'> <Pagination
          currentPage={currentPage}
          TotalPage={TotalPage}
          currentSet={currentSet}
          setCurrentSet={setCurrentSet}
          setcurrentPage={setcurrentPage}
        />
        </div>
      </div>
      {show && 
            <PreviewFileModal 
            show={show} 
            handleClose={handleClose} 
         
            fileUrl={fileBlob} 
             fileType={fileType}
            approved={approved} 
            approvedShow={approvedShow} 
            handleapprovedFile={handleapprovedFile} 
            />
      }
    </div>
  )
}

export default Documents_files
