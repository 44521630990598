import React, { useEffect, useState } from 'react'
import { Button, ProgressBar } from 'react-bootstrap'
import io from "socket.io-client";
import FilesService from '../../services/files.service';
import { useSelector } from 'react-redux';
import { validateFileUpload } from '../../utils/validations';
import Error from '../../components/common/Error';
// const socket = io(process.env.REACT_APP_API_URL);
const Document_file_Upload = ({uploadFilehandleClose}) => {
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadIndex, setUploadIndex] = useState(0);
    const [files, setFiles] = useState([]);
    const [selectedClients, setSelectedClients] = useState("");
    const [fileDetails, setFileDetails] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [fileuploadingError, setfileuploadingError] = useState(null);
    const userId = localStorage.getItem('userId');
    const clientDetails = useSelector((state) => state.client.client);
//  useEffect(() => {
//         socket.on('uploadProgress', (data) => {
//             setProgress(data.progress);
//         });
  
//         socket.on('uploadError', (data) => {
//             console.error('Error during file upload:', data.message);
//             setUploading(false);
//         });
  
//         return () => {
//             socket.off('uploadProgress');
//             socket.off('uploadError');
//         };
//     }, []);




    const handleFileSelect = (event) => {



        setFiles([...files, ...event.target.files]);
       
      };

      const handleFileUpload = async (file, clientId) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('clientId', clientId);

 try {
          setProgress(0);
          setUploading(true);
  
     const Filesdetails= await FilesService.UploadFiles(formData);
             
       setFileDetails((prevDetails) => [
        ...prevDetails,
        {
            originalFileName: Filesdetails.originalFileName,
            uniqueFileName: Filesdetails.uniqueFileName,
          
        }
    ]);
          setUploadIndex((prevIndex) => prevIndex + 1); 
          setUploading(false);
        } catch (error) {
          console.error('Error uploading file:', error);
          setfileuploadingError(error.response.data.errors[0].msg)
          setUploading(false);
        }
      };

      useEffect(() => {
        if (uploadIndex < files.length  && !uploading&& !fileuploadingError) {
   
          handleFileUpload(files[uploadIndex], selectedClients);
        }
      }, [uploadIndex, files, selectedClients,uploading,fileuploadingError]);
  


      const handleSelectChangeCompose=(event)=>{
        setSelectedClients(event.target.value)
     
      }


const saveFile=async()=>{

  const error = validateFileUpload(selectedClients,files);
  if (error) {
    setErrorMessage(error);
    return;
  }
  
const data={
  clientId: selectedClients,
  files: fileDetails,
}

        try{
      
          const response = await FilesService.savefile(data);
          uploadFilehandleClose()
        
        }catch(err){
      console.log(err)
        }
      }
      





  return (
                           <div>
                           <div>





                           <div className='company-filter mb-3'>
            <select className="form-select form-select-sm" aria-label="Default select example" onChange={handleSelectChangeCompose}>
              <option selected disabled>By Corporation</option>
              <option value="all">All</option>

              {clientDetails.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>
          {errorMessage.client && <Error error={errorMessage.client}/>}




                            <div className='d-flex flex-column mb-3'>
                                <input type="file" id="attachFiles" name="Browse Files" className="form-control mb-3" multiple onChange={handleFileSelect} disabled={!selectedClients} />
                                <label htmlFor="attachFiles" className="form-label fs-6 text-secondary ps-2 ">Supportd File</label>
                            </div>
                        </div>

                        {errorMessage.files && <Error error={errorMessage.files}/>}
                        <ul className="list-group gap-2">
                        { files.map((file, index) => (   <li className="list-group-item d-flex justify-content-between align-items-center p-3" style={{ backgroundColor: "#F6F6F6" }} >
                                <div className='d-flex align-items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#0A6847" className="bi bi-file-earmark-excel-fill me-2" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1M5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64" />
                                    </svg>
                                    <span className="fw-semibold">{file.name}</span>
                                </div>
                                {uploadIndex === index && (
              <div className="progressbar-container">
                <ProgressBar now={progress} label={`${progress}%`} />
              </div>
            )}
                                <div>
                                    <button type="button" className="btn bg-white text-black fw-semibold">
                                        Remove
                                    </button>
                                </div>
                            </li> ))}
                      
                        </ul>

                        <div className="action-buttons">
                        <Button variant="outline-primary" onClick={uploadFilehandleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={()=>saveFile(files)}>
                            Save
                        </Button>
                        </div>


    </div>
  )
}

export default Document_file_Upload
