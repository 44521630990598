import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

import { Table } from 'react-bootstrap';
import { formatDateTime } from '../../utils/dateFormat';
import { recoverIcon } from '../../utils/icons';
const Documents_trashed = ({trashedFile,handleRecover}) => {

  return (
    <div>
     <div className='table-responsive'  >
                    <Table hover>
                        <thead>
                            <tr className="">
                                <th className="text-secondary p-3 text-start " >Name</th>
                                <th className="text-secondary p-3 ">Uploaded on</th>
                                <th className="text-secondary p-3 ">Recover</th>

                            </tr>
                        </thead>
                        <tbody>
{       trashedFile&&trashedFile.map((trashedFile)=>(
    <tr>
                                <td className="customText fw-semibold p-3 w-70 text-start "> <FontAwesomeIcon icon={faFilePdf} classname="pe-2" style={{color: "#e66565", paddingRight:"5px"  }} size=""  /> <a href="" className='text-dark'>{trashedFile.filename}</a>  </td>
                                <td className='customText p-3 w-20'>{formatDateTime(trashedFile.created_at)}</td>
                                <td className="recover">
                           <img src={recoverIcon} alt="" onClick={()=>handleRecover(trashedFile.id)}/>
    </td>

                            </tr>
))}

                        </tbody>
                    </Table>
                </div>
    </div>
  )
}

export default Documents_trashed
