import React from "react";
import { getFileIcon } from "../../utils/fileIcon";

const ChatFiles = ({ files, handleShow}) => {

  // Utility to extract the extension from the file name
  const getFileExtension = (fileName) => {
    return fileName.split('.').pop().toLowerCase();
  };

  return (
    <div className="message__files">
        <span className="text-muted title-attachments">Attachment(s):</span>
      {files.map((file) => {
        const fileExtension = getFileExtension(file.file_name);

        return (
          <div key={file.id} className="message__file">
           
               {/* Show file icon and name for non-image file types */}
              <div className="file-bubble document-file" onClick={() => handleShow(file)}>
                <img
                  src={getFileIcon(file.file_name)}
                  alt={file.file_name}
                  className="file-icon"
                />
                <span className="file-name">{file.file_name}</span>
              </div>
            {/* )} */}
          </div>
        );
      })}
    </div>
  );
};

export default ChatFiles;
