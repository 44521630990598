import React, { useEffect, useState } from 'react'
import Default from '../../components/layouts/default'
import { FormControl, InputGroup, Modal } from 'react-bootstrap';
import Documents_files from './Documents_files';
import Documents_folder from './Documents_folder';
import Documents_trashed from './Documents_trashed';
import Document_file_Upload from './Document_file_Upload';
import FilesService from '../../services/files.service';
import View_Approved from './View_Approved';
import { useDispatch, useSelector } from 'react-redux';
import FolderService from '../../services/folders.service';
import Documents_folder_details from './Document_folder_details';
import messages from '../../utils/meesage';
import { setLoading } from '../../feature/loading.slice';
import { setMessage } from '../../feature/message.slice';

const Documents = () => {
    const [selectedClients, setSelectedClients] = useState("");
    const [showUploadFile, setUploadFile] = useState(false);
    const [showViewFile, setViewFile] = useState(false);
    const uploadFilehandleClose = () => setUploadFile(false);
    const uploadFilehandleShow = () => setUploadFile(true);
    const uploadViewhandleClose = () => setViewFile(false);

    const [activate, setActivate] = useState(1);
    const dispatch = useDispatch();

    const [files, setfiles] = useState([])
    const [fileId, setfileId] = useState("")
    const [trashedFile, settrashedFile] = useState([])
    const [searchKeyword, setsearchKeyword] = useState("")

    const [approved, setapproved] = useState(false)
    const [folders, setfolders] = useState([])
    const [folderDetails, setfolderDetails] = useState([])
    const [folderName, setfolderName] = useState("")

    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [currentPage, setcurrentPage] = useState(1)
    const [currentSet, setCurrentSet] = useState(1);

    const [TotalPage, setTotalPage] = useState("")

    const [sortBy, setSortBy] = useState("created_at"); // Default sorting column
    const [sortDirection, setSortDirection] = useState("desc");

    const clientDetails = useSelector((state) => state.client.client);


    const getFolderNameById = (id) => {
        const SelectedClientByID = clientDetails && clientDetails.find(client => client.id == id)


        return SelectedClientByID && SelectedClientByID.folderName

    }

    const uploadViewhandleShow = async (files) => {

        if (files.approval_status == "approved") {
            setapproved(true)
        } else {
            setapproved(false)
        }
        setfileId(files.id)

        const userFolder = await getFolderNameById(files.attachment_id)


        const UPLOAD_FOLDER = `${process.env.REACT_APP_MEDIA_URL}/permanent/${userFolder}/`
        const fileUrl = `${UPLOAD_FOLDER}${files.diskname}`;
        setSelectedFileUrl(fileUrl);
        setViewFile(true)
    };





    const handleBUtton = (containerId) => {
        setActivate(containerId);
    }


    const handleChangeSearch = (e) => {
        setsearchKeyword(e.target.value)
    }




    const getFiles = async () => {
        const data = {
            search: searchKeyword,
            page: currentPage,
            client_id: selectedClients || null,
            sortBy: sortBy,
            sortDirection: sortDirection
        }
        try {
            const files = await FilesService.getFile(data)
            setcurrentPage(files.currentPage)
            setTotalPage(files.totalPages)
            setfiles(files.files)
        } catch (err) {
            console.log(err)
        }

    }



    const getTrashedFile = async () => {
        const data = {
            search: searchKeyword,
            page: currentPage,
            sortBy:sortBy,
            sortDirection:sortDirection
        }
        try {
            const trashedFile = await FilesService.getTrashaedFile(data)
            // console.log(trashedFile)
            settrashedFile(trashedFile.files)

            setcurrentPage(trashedFile.currentPage)
            setTotalPage(trashedFile.totalPages)
        } catch (err) {
            settrashedFile([])
            console.log(err)
        }
    }


    const handleDelete = async (id) => {

        try {
            dispatch(setLoading(true));
            dispatch(setMessage(messages.loading));
            await FilesService.DeleteFile(id)
            getFiles()
            getTrashedFile()
            dispatch(setMessage(messages.success.FILE_DELETE));
        } catch (err) {
            console.log(err)
        } finally {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 3000);
        }


    }
    const handleRecover = async (id) => {
        try {
            dispatch(setLoading(true));
            dispatch(setMessage(messages.loading));
            await FilesService.RecoverFile(id)
            getFiles()
            getTrashedFile()
            dispatch(setMessage(messages.success.RECOVER_FILE));
        } catch (err) {

            console.log(err)
        } finally {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 3000);
        }
    }



    const approvedFile = async () => {
        try {
            await FilesService.approvedFile(fileId)
        } catch (err) {
            console.log(err)
        }
    }




    const getAllFolders = async () => {
        const data = {
            search: searchKeyword,
            page: currentPage,
            sortBy:sortBy,
            sortDirection:sortDirection
        }
        try {
            const Folders = await FolderService.getAllFolders(data)

            setfolders(Folders.data)

            setcurrentPage(Folders.currentPage)
            setTotalPage(Folders.totalPages)
        } catch (err) {
            console.log(err)
        }
    }

    const getFolderDetails = async (folderId, name) => {
        setfolderName(name)
        const data = {
            searchKeyword: searchKeyword,
            folderId: folderId,
            page: currentPage
        }
        try {
            const folderDetails = await FilesService.getFile(data)

            setfolderDetails(folderDetails.files)
        } catch (err) {
            console.log(err)
        }
    }



    useEffect(() => {
        if (activate === 1) {

            getFiles()
        }
        if (activate === 2) {
            getAllFolders()

        }
        if (activate === 3) {

            getTrashedFile()
        }
    }, [searchKeyword, currentPage, activate, selectedClients,sortBy,sortDirection])


    const handleSelectChangeCompose = (event) => {
        setSelectedClients(event.target.value);

    };

    return (

        <Default pageTitle="Documents">
            <div className="documents card w-100">
                <div className="card-body">
                    <div className="documents__header">
                        {activate === 4 ? (
                            <div className="documents__folder-info">
                                <span>{folderName}</span>
                                <span className="chip-close-icon" onClick={() => setActivate(2)}>
                                    &times;
                                </span>
                            </div>
                        ) : (
                            <div className="documents__tabs">
                                <div
                                    className={activate === 1 ? "tab is-active" : "tab"}
                                    onClick={() => handleBUtton(1)}
                                >
                                    Recent Files
                                </div>
                                <div
                                    className={activate === 2 ? "tab is-active" : "tab"}
                                    onClick={() => handleBUtton(2)}
                                >
                                    Folders
                                </div>
                                <div
                                    className={activate === 3 ? "tab is-active" : "tab"}
                                    onClick={() => handleBUtton(3)}
                                >
                                    Trashed
                                </div>
                            </div>
                        )}

                        <div className="documents__toolbar w-50 d-flex justify-content-end">
                            <select onChange={handleSelectChangeCompose} className='form-select form-select-sm' style={{ maxWidth: "200px" }}>
                                <option selected disabled>By Corporation</option>
                                <option value="">All</option>
                                {clientDetails.map((client) => (
                                    <option key={client.id} value={client.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </select>

                            <div className="documents__search">
                                <input
                                    type="search"
                                    placeholder="Search"
                                    onChange={handleChangeSearch}
                                    className='form-control form-control-sm'
                                />
                            </div>

                            <button type="button" className='btn btn-sm btn-primary' onClick={uploadFilehandleShow}>
                                Upload file
                            </button>
                        </div>
                    </div>

                    <div className="documents__content">
                        {activate === 1 && (
                            <Documents_files
                                files={files}
                                handleDelete={handleDelete}
                                uploadViewhandleShow={uploadViewhandleShow}
                                getFiles={getFiles}
                                currentPage={currentPage}
                                TotalPage={TotalPage}
                                currentSet={currentSet}
                                setCurrentSet={setCurrentSet}
                                setcurrentPage={setcurrentPage}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                setSortDirection={setSortDirection}
                                sortDirection={sortDirection}
                            />
                        )}
                        {activate === 2 && (
                            <Documents_folder
                                folders={folders}
                                setActivate={setActivate}
                                getFolderDetails={getFolderDetails}
                                currentPage={currentPage}
                                TotalPage={TotalPage}
                                currentSet={currentSet}
                                setCurrentSet={setCurrentSet}
                                setcurrentPage={setcurrentPage}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                setSortDirection={setSortDirection}
                                sortDirection={sortDirection}
                            />
                        )}
                        {activate === 3 && (
                            <Documents_trashed
                                trashedFile={trashedFile}
                                handleRecover={handleRecover}
                                currentPage={currentPage}
                                TotalPage={TotalPage}
                                currentSet={currentSet}
                                setCurrentSet={setCurrentSet}
                                setcurrentPage={setcurrentPage}
                                sortBy={sortBy}
                                setSortBy={setSortBy}
                                setSortDirection={setSortDirection}
                                sortDirection={sortDirection}
                            />
                        )}
                        {activate === 4 && <Documents_folder_details folderDetails={folderDetails} />}
                    </div>
                </div>
            </div>

            <Modal className='modal-lg p-0 m-0' show={showUploadFile} onHide={uploadFilehandleClose} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Upload File(s)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-0'>
                    <Document_file_Upload uploadFilehandleClose={uploadFilehandleClose} getFiles={getFiles} />
                </Modal.Body>

            </Modal>

            <Modal className='modal-lg p-0 m-0' show={showViewFile} onHide={uploadViewhandleClose} animation={true}>

                <Modal.Body>
                    <View_Approved uploadFilehandleClose={uploadViewhandleClose} selectedFileUrl={selectedFileUrl} approvedFile={approvedFile} approved={approved} />
                </Modal.Body>

            </Modal>
        </Default>
    )
}

export default Documents
