import React, { useState, useEffect, useRef } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from 'react-bootstrap';
import chatService from '../../services/chat.service';
import { expandIcon } from '../../utils/icons'
import { formatTime } from '../../utils/dateFormat';

import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../feature/message.slice';
import { setLoading } from '../../feature/loading.slice';
import messages from '../../utils/meesage';

const ThreadDetails = ({ threadId,UserId, fetchThreads, subject, hasTasks, setShowTasks, showTasks, message,fetchThreadMessage }) => {

  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.loading.loading);
  const loadingmessage= useSelector((state) => state.message.message);

  const [newMessage, setNewMessage] = useState("");

  const chatContainerRef = useRef(null);



  const handleMessageChange = async (content) => {
    const cleanedContent = content.replace(/<p><br><\/p>/g, '');
    setNewMessage(cleanedContent);
  };


  const sendMessage = async () => {
    const data = {
      thread_id: threadId,
      messageBody: newMessage,
    };


    try {
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
      await chatService.SendNewMessage(threadId, data);
      fetchThreadMessage(threadId)
      fetchThreads()
      setNewMessage("");

    } catch (err) {
      console.log(err);
    }finally {
      dispatch(setLoading(false));

    }
  };


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [message]);
  return (
    <div className="thread">
      <div className='container-fluid'>
        <div className="thread__header">
          <span className='thread__subject'>{subject}</span>
          {hasTasks && (<a onClick={() => setShowTasks(!showTasks)} className={`toggle-tasks ${showTasks ? 'tasks-expanded' : 'tasks-collapsed'} `}><img src={expandIcon} width={24} /></a>)}
        </div>
        <div className="thread__chats" >
          <div className='thread__chats__chats' ref={chatContainerRef} >
            {message && message.map((msg) => (
              <div
                key={msg.id}
                className={`message ${msg.senderid == UserId ? 'by-user' : 'by-other'}`}
              >
                <div className="message__header">
                  <span className="message__sender">{msg.senderid == UserId ? 'You' : msg.senderName}</span>
                  <span className="message__time">{formatTime(msg.created_at)}</span>
                </div>
                <div className="message__message" dangerouslySetInnerHTML={{ __html: msg.body }} />
              </div>
            ))}
          </div>
        </div>
        <div className="thread__newmessage">

          <ReactQuill
            value={newMessage}
            onChange={handleMessageChange}
            placeholder="Type Message here..."
            theme="snow"
            modules={{
              toolbar: [
                [{ size: [] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ],
            }}

          />
          {/* <Button variant="outline-primary" className="btn btn-sm" onClick={sendMessage}>
            Send
          </Button> */}

          <Button variant="outline-primary" className="btn btn-sm" onClick={sendMessage} disabled={isLoading} >
          {isLoading?(loadingmessage):("Send")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThreadDetails;
