import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFolder } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
const Documents_folder = ({folders,setActivate,getFolderDetails}) => {

const openFolderDetails=(id,name)=>{
    setActivate(4)
    getFolderDetails(id,name)
}


  return (
    <div>
       <div className='table-responsive'  >
                    <Table hover   >
                        <thead>
                            <th className="text-secondary w-50 p-3 text-start " >Name</th>
                        </thead>
                        <tbody>
                          { folders&&folders.map((folderDetails)=>(
                            <tr>
                                <td className="text-start fw-semibold p-3"  onClick={()=>openFolderDetails(folderDetails.id,folderDetails.name)}>
                                   <FontAwesomeIcon icon={faFolder} style={{color: "#74C0FC", paddingRight:"5px" }} />
                                    <span>{folderDetails.name}</span> </td>
                            </tr>
                          ))
}
                        </tbody>
                    </Table>
                </div>
    </div>
  )
}

export default Documents_folder
