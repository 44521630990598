import React, { useEffect, useState } from 'react'
import Default from '../../components/layouts/default'
import { FormControl, InputGroup, Modal } from 'react-bootstrap';
import Documents_files from './Documents_files';
import Documents_folder from './Documents_folder';
import Documents_trashed from './Documents_trashed';
import Document_file_Upload from './Document_file_Upload';
import FilesService from '../../services/files.service';
import View_Approved from './View_Approved';
import { useSelector } from 'react-redux';
import FolderService from '../../services/folders.service';
import Documents_folder_details from './Document_folder_details';

const Documents = () => {

    const [showUploadFile, setUploadFile] = useState(false);
    const [showViewFile, setViewFile] = useState(false);
 const uploadFilehandleClose = () => setUploadFile(false);
 const uploadFilehandleShow = () => setUploadFile(true);
const uploadViewhandleClose = () => setViewFile(false);

const [activate, setActivate] = useState(1);


 const[files,setfiles]=useState([])
 const[fileId,setfileId]=useState("")
const [trashedFile,settrashedFile]=useState([])
 const [searchKeyword,setsearchKeyword]=useState("")
 const [approved,setapproved]=useState(false)
const [folders,setfolders]=useState([])
const [folderDetails,setfolderDetails]=useState([])
const [folderName,setfolderName]=useState("")

const [selectedFileUrl, setSelectedFileUrl] = useState(null);


const clientDetails = useSelector((state) => state.client.client);


const getFolderNameById=(id)=>{
const SelectedClientByID=clientDetails&&clientDetails.find(client=>client.id==id)


return SelectedClientByID&& SelectedClientByID.folderName

}

const uploadViewhandleShow = async(files) => {

if(files.approval_status=="approved"){
    setapproved(true) 
}else{
    setapproved(false) 
}
    setfileId(files.id)
    
const userFolder=await getFolderNameById(files.attachment_id)


const UPLOAD_FOLDER = `${process.env.REACT_APP_MEDIA_URL}/permanent/${userFolder}/`
const fileUrl = `${UPLOAD_FOLDER}${files.diskname}`;
setSelectedFileUrl(fileUrl); 
setViewFile(true)
};
const handleBUtton = (containerId) => {
        setActivate(containerId);
    }


    const handleChangeSearch=(e)=>{
        setsearchKeyword(e.target.value)
      }


 const userId = localStorage.getItem('userId');

 const getFiles=async()=>{
    const data={
     searchKeyword:searchKeyword
    }
    try{
const files=await FilesService.getFile(data)
console.log(files)
setfiles(files)
    }catch(err){
        console.log(err)
    }

}



const getTrashedFile=async()=>{
    try{
const trashedFile=await FilesService.getTrashaedFile(userId)
console.log(trashedFile)
settrashedFile(trashedFile)
    }catch(err){
        settrashedFile([])
        console.log(err)
    }
}


const handleDelete=async(id)=>{
 
    try{
    await FilesService.DeleteFile(id)
    getFiles()
    getTrashedFile()
    
    }catch(err){
      console.log(err)
    }
    

    }
 const handleRecover=async(id)=>{
        try{
            await FilesService.RecoverFile(id)
            getFiles()
            getTrashedFile()
        }catch(err){

console.log(err)
        }
    }


    const approvedFile=async()=>{
try{
await FilesService.approvedFile(fileId)
}catch(err){
    console.log(err)
}
    }




const getAllFolders=async()=>{
    const data={
        searchKeyword:searchKeyword
    }
    try{
const Folders=await FolderService.getAllFolders(data)

setfolders(Folders.data)
    }catch(err){
        console.log(err)
    }
}

const getFolderDetails=async(folderId,name)=>{
    setfolderName(name)
    const data={
        searchKeyword:searchKeyword,
        folderId:folderId
       }
    try{
const folderDetails=await FilesService.getFile(data)

setfolderDetails(folderDetails)
    }catch(err){
        console.log(err)
    }
}

useEffect(()=>{
    getFiles()
    getTrashedFile()
    getAllFolders()
},[searchKeyword])

    return (
        <Default>
            <div className='Documents_wrapper card w-100'>

                <div className=" d-flex flex-column-reverse flex-lg-row justify-content-between py-4 px-4 " >

{activate==4? (    <div className="chip">
                    <span>{folderName}</span>
                    <span 
                      className="chip-close-icon" 
                      onClick={() => setActivate(2)}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    >
                      &times;
                    </span>
                  </div>):(<div className="input-group p-2  border rounded border-0 justify-content-between custom-tabs w-25 w-sm-100 mt-3 mt-lg-0  " style={{ backgroundColor: "#F2F2F2" }}>
                        <label className={"input-group-text border-0 bg-transparent text-black  " + (activate === 1 ? 'is-active' : '')} htmlFor="inputGroupFile01" onClick={() => handleBUtton(1)} >Recent Files</label>
                        <label className={"input-group-text border-0 bg-transparent text-black " + (activate === 2 ? 'is-active' : '')} htmlFor="inputGroupFile01" onClick={() => handleBUtton(2)}  >Folders</label>
                        <label className={"input-group-text border-0 bg-transparent text-black " + (activate === 3 ? 'is-active' : '')} htmlFor="inputGroupFile01" onClick={() => handleBUtton(3)} >Trashed</label>
                    </div>)}


               
                    <div className="d-flex align-items-lg-center align-items-sm-start flex-column flex-lg-row ">
                        <InputGroup className=" pe-1 shadow-none ">
                            <FormControl
                                type="search"
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="button-addon2"
                                style={{ boxShadow: 'none', borderColor: '' }}
                                onChange={handleChangeSearch}
                            />
                        </InputGroup>
                        <button type="button " style={{ width: '200px', height: "40px", fontSize: '13px' }} class="btn btn-primary btn-sm mt-3 mt-lg-0 " onClick={uploadFilehandleShow}  >Upload file</button>
                    </div>
                </div>


                <div>
                        {activate === 1 && <Documents_files  files={files}  handleDelete={handleDelete} uploadViewhandleShow={uploadViewhandleShow}/>}
                    {activate === 2 && <Documents_folder folders={folders}  setActivate={setActivate}   getFolderDetails={getFolderDetails}/>}
                    {activate === 3 && <Documents_trashed  trashedFile={trashedFile}  handleRecover={handleRecover}/>}
                    {activate === 4 && <Documents_folder_details   folderDetails={folderDetails}/>}


                </div>




                <Modal className='modal-lg p-0 m-0' show={showUploadFile} onHide={uploadFilehandleClose} animation={true}>
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Upload File(s)
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='m-0'>
                        <Document_file_Upload uploadFilehandleClose={uploadFilehandleClose} />
                    </Modal.Body>

                </Modal>

                <Modal className='modal-lg p-0 m-0' show={showViewFile} onHide={uploadViewhandleClose} animation={true}>
              
                    <Modal.Body>
                        <View_Approved uploadFilehandleClose={uploadViewhandleClose} selectedFileUrl={selectedFileUrl}  approvedFile={approvedFile} approved={approved}/>
                    </Modal.Body>

                </Modal>


            </div>

        </Default>
    )
}

export default Documents
