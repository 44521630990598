import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {  showPasswordIcon, hidePasswordIcon } from '../../utils/icons'; 
import LoginAuth from '../../services/auth.service';
import Auth from '../../components/layouts/auth';
import { validateForm } from '../../utils/validations';
import IpService from '../../services/Ip.service';
import { 
  isMobile, isTablet, isBrowser, 
  browserName, browserVersion, 
  osName, osVersion, deviceType 
} from 'react-device-detect';
import ClientService from '../../services/client.service';
import { setClientId } from '../../feature/client.slice';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../feature/loading.slice';
import { setMessage } from '../../feature/message.slice';
import messages from '../../utils/meesage';
const SignIn = () => {
 
  const isLoading = useSelector((state) => state.loading.loading);
  const message= useSelector((state) => state.message.message);
  console.log("isLoading",isLoading)
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  if(queryParams){

    var emailFromUrl = queryParams.get('email');
  }


  const [ip, setIp] = useState('');
  const [deviceInfo, setDeviceInfo] = useState([]);

  const dispatch = useDispatch()
  
  const [formData, setFormData] = useState({
    email:emailFromUrl||'',
    password: '',
    rememberMe: false,
 
  });

  const fetchIp = async () => {
    try {
      const response = await IpService.getIp();
      setIp(response.ip);
      // console.log(response)
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }


  }

  useEffect(()=>{
    fetchIp()
    const deviceData = [
      { label: 'Browser', value: `${browserName} ${browserVersion}` },
      { label: 'OS', value: `${osName} ${osVersion}` },
      { label: 'Device Type', value: deviceType },
      { label: 'Is Mobile', value: isMobile ? 'Yes' : 'No' },
      { label: 'Is Tablet', value: isTablet ? 'Yes' : 'No' },
      { label: 'Is Browser', value: isBrowser ? 'Yes' : 'No' }
    ];

    setDeviceInfo(deviceData);
  },[])

  const [errors, setErrors] = useState({ email: '', password: '' });
  const [ApiError, setApiError] = useState(false);
  const [buttonClick, setbuttonClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ApiErrorMessage, setApiErrorMessage] = useState("");

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value
    }));
  };


const data={
  email: formData.email,
  password: formData.password,
  rememberMe: false,
  ipAddress:ip,
  deviceInfo:deviceInfo


}
  const login = () => LoginAuth.login(data);
  const client =async(userId)=>{
    try {
      const data = { userId: userId };
      const user = await ClientService.getclient(data);
      const clientId=user.clientIds
  
      localStorage.setItem('client', JSON.stringify(clientId));
      // console.log(user)
      dispatch(setClientId(clientId[0].id));
    } catch (err) {
      console.log(err);
    }
  }

  const handleSignIn = async (event) => {
    event.preventDefault()
   

    

    const formErrors = validateForm(formData); 
    setErrors(formErrors); 

    if (Object.keys(formErrors).length === 0) {
      try {
        dispatch(setLoading(true));
        dispatch(setMessage(messages.loading));
        const users = await login();
    
        const id = users.data.user.user.id;
        client(id)
        setbuttonClick(true);
        setApiError(false);
        setApiErrorMessage(users.data.message);
        navigate(`/dashboard`);
    
      } catch (err) {
    
    setbuttonClick(true);
        setApiError(true);
        setApiErrorMessage(err.response.data.error);
        setTimeout(() => {
          setApiError(false);
          setApiErrorMessage('');
        }, 3000);
      }finally {
        dispatch(setLoading(false));
  
      }
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  

  return (
    <Auth buttonClick={buttonClick} ApiError={ApiError} ApiErrorMessage={ApiErrorMessage} pageTitle="Sign In">
      <Form  onSubmit={handleSignIn}>
        
            <Form.Group className="form-group mb-3">
              <div className="text-center">
                <Form.Label htmlFor="email">Email</Form.Label>
              </div>
              <Form.Control
                type="email"
                isInvalid={!!errors.email}
                id="email"
                aria-describedby="email"
                placeholder=" "
                value={formData.email}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          
            <Form.Group className="form-group">
              <div className="text-center">
                <Form.Label htmlFor="password">Password</Form.Label>
              </div>
              <div className="position-relative">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  isInvalid={!!errors.password}
                  id="password"
                  aria-describedby="password"
                  
                  value={formData.password}
                  onChange={handleChange}
                />
                {!errors.password &&   (
                <span
                  className="position-absolute password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  <img src={`${showPassword ? hidePasswordIcon :  showPasswordIcon }`} width={20} style={{opacity: "0.3"}} />
                </span>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
          
          <div lg="12" className="d-flex justify-content-between py-3">
        
     
            <Link to={`/forget-password?email=${encodeURIComponent(formData.email)}`} className="forgot-password-link">Forgot Password?</Link>

          </div>
        
        <div className="text-center">

          <Button className='btn-expanded'  type="submit" variant="btn btn-primary" disabled={isLoading}>{isLoading?(message):("Sign In")}</Button>

        </div>
        <div className='text-center pt-3'>
         
          <Link  to={`/login-otp?email=${encodeURIComponent(formData.email)}`} className="secondary-cta">or Login with OTP in Email</Link>

        </div>
      </Form>
    </Auth>
  );
};

export default SignIn;
