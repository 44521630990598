import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';



import DashBoard from './pages/Dashboard/DashBoard';
import Documents from './pages/Documents/Documents';

import SignIn from './pages/Auth/sign-in';
import ForgotPassword from './pages/Auth/forgot-password';
import ResetPassword from './pages/Auth/reset-password';

import Login_Otp from './pages/Auth/login-otp';
import Login_VerifyOtp from './pages/Auth/login-otp-verify';


import Chats from './pages/Chat/Chats';
import CompanyData from './pages/Companydata/CompanyData_ClientsList';
import CompanyDetails from './pages/Companydata/CompanyData';

import BillingCustom from './pages/Billing';
import ChatsandCoversation from './pages/Chat/ChatsandCoversation';
import Default from './components/layouts/default';
import Auth from './components/layouts/auth';
import ProtectedRoutes from './utils/ProtectedRoutes';
import PublicRoutes from './utils/publicRoutes';
const App = () => {
  return (



<Router>
      <Routes>
       <Route  path="/" element={<ProtectedRoutes/>}>

     
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/chats" element={<Chats />} />
        <Route path="/CompanyData" element={<CompanyData/>} />
        <Route path="/CompanyData/:id" element={<CompanyDetails/>} />

        <Route path="/BillingCustom" element={<Default><BillingCustom/></Default>} />
        <Route path="/chatsandCoversation/:id/:page" element={<Default><ChatsandCoversation/></Default>} />
        </Route>

         <Route element={<PublicRoutes/>}>
          <Route path="/login" element={<SignIn/>} />
         </Route>
        <Route path="/forget-password" element={<ForgotPassword/>} />
        <Route path="/reset-password" element={<ResetPassword/>} />

        <Route path="/login-otp" element={<Login_Otp/>}/>
        <Route path="/login-VerifyOtp/:id" element={<Login_VerifyOtp/>}/>
      


      </Routes>
    </Router>



  )
}

export default App
