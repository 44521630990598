import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf , faFileWord ,faFolder } from '@fortawesome/free-solid-svg-icons';
import { Table, InputGroup, FormControl, Badge } from 'react-bootstrap';
import { formatDateTime } from '../../utils/dateFormat';
import { trashIcon } from '../../utils/icons';
import { downloadFile } from '../../utils/downloadFiles';
import PreviewFileModal from '../../components/common/Filemanager_PreviewFileModal';
import FilesService from '../../services/files.service';
const Documents_folder_details = ({folderDetails}) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [fileBlob, setFileBlob] = useState(null);
    const [fileType, setFileType] = useState(null);
    const downloadExtensions = ['docx', 'xlsx', 'pptx'];
  
  
    const handleShow=async(file)=>{
      try{
      const res= await FilesService.filePreview(file.id)
        
          if (res) {
          
          const blob = res.data;
          const blobUrl=URL.createObjectURL(blob)
          setFileBlob(blobUrl); 
          setFileType(res.headers['content-type'])
          if (downloadExtensions.includes(file.file_type) &&blobUrl ){
           downloadFile(blobUrl, file.file_name);
           }else{
          setShow(true);
        }
        } else {
          console.error('Failed to fetch file');
        }
      }catch(err){
        setFileBlob(null)
        setShow(true);
        console.log(err)
      
      }
      
      
      
      
      }
  

return (
    <div>
         <div className='table-responsive table-striped' >
                    <Table hover Table-striped >
                        <thead className='bg-dark'>
                            <tr className="">
                                <th className="text-secondary w-50 p-3 text-start " >Name</th>
                                <th className="text-secondary w-50 p-3 text-start " >Uploaded by</th>

                                <th className="text-secondary p-3 text-start ">Uploaded on</th>
                                <th className="text-secondary p-3 text-start ">Status</th>
                                {/* <th className="text-secondary p-3 text-start ">action</th> */}

                            </tr>
                        </thead>
                        <tbody>


                         {folderDetails.length>0&&folderDetails.map((files)=>(
                            <tr className='text-start' >
                                <td className="customText fw-semibold p-3" >
                                      <FontAwesomeIcon icon={faFilePdf} classname="pe-2" style={{color: "#e66565", paddingRight:"10px" }} size=""  />
                                 <span className='text-dark'  onClick={()=>handleShow(files)}>{files.file_name}</span> </td>
                                <td className='customText p-3'>{files.uploaded_by}</td>

                                <td className='customText p-3'>{files.uploaded_on}</td>
                                <td className='p-3'><Badge pill bg='dark' className='fw-semibold' >{files.status}</Badge></td>
                                {/* <td className="action ">
                    {   files.uploaded_by=="clientportal_user"&&
                        <div>
                    <img src={trashIcon} alt="folder icon" onClick={()=>handleDelete(files.id)}/>
                  </div>}
                                

                                </td> */}
                            </tr>
                         ))  }
                          


                        </tbody>
                    </Table>
                </div>
                {show&& <PreviewFileModal show={show} handleClose={handleClose}  fileUrl={fileBlob} 
             fileType={fileType}/>}
    </div>
  )
}

export default Documents_folder_details
