import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import chatService from '../../services/chat.service';
import Default from '../../components/layouts/default';
import ChatHeader from './ChatHeader';
import ChatList from './ChatList';
import Pagination from './Pagination';
import NewChatModal from './NewChatModal';
import ThreadDetails from './ThreadDetails';
import TaskList from './TaskList';
import ConfirmationBox from './ConfirmationBox';
import { fetchAllThreads } from '../../feature/chat.slice';
import { useLocation, useNavigate } from 'react-router-dom';
// import io from 'socket.io-client';
// const socket = io(process.env.REACT_APP_API_URL);
import { setMessage } from '../../feature/message.slice';
import { setLoading } from '../../feature/loading.slice';
// import messages from '../../utils/meesage';
const TaskAndChats = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const NotificationThreadId = location.state?.NotificationThreadId;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    
    setShow(false)
    resetForm()};
  const handleShow = () => setShow(true);
  const [Threads, setThreads] = useState([]);

  const [UserId, setUserId] = useState("");
  const [NewMessage, setNewMessage] = useState("");
  const [searchKeywords, setsearchKeywords] = useState("");
  const [subject, setSubject] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [TotalPage, setTotalPage] = useState("");
  const [client_id,setclient_id]=useState("")

  const [selectedThreadId, setSelectedThreadId] = useState(null);

  const [tasks, setTasks] = useState([]);


  const [hasTasks, setHasTasks] = useState(false); 

  const [showTasks, setShowTasks] = useState(true); 
  const [unreadCount,setunreadCount]=useState("")
  const [showModal, setShowModal] = useState(false);

  const [selectedClient, setSelectedClient] = useState("");
  const[selectedClientCompose,setselectedClientCompose]=useState("")
  const [fetchNotificationBysocket, setfetchNotificationBysocket,] = useState(false)
  const [threadmessage, setthreadMessage] = useState([]);
 

  const clientDetails = useSelector((state) => state.client.client);
 

  const userId = localStorage.getItem('userId');
  const dispatch = useDispatch()


  const fetchThreads = async () => {
  
    setUserId(userId);
    const data = {
      clientIds: selectedClient,
      searchKeyword: searchKeywords,
      page: currentPage
    };

    try {
      const Threads =await dispatch(fetchAllThreads({data})).unwrap()
      console.log(Threads)
      setThreads(Threads.threads);
      setunreadCount(Threads.unreadMessageCount)
      setTotalPage(Threads.totalPages);
      setcurrentPage(Threads.currentPage);
    } catch (err) {
      setThreads([]);
      console.log(err);
    }
  };


const fetchThreadMessage=async(thread_id)=>{
  const data={
    thread_id:thread_id
  }
  try{
const SelectedThreadMessages=await chatService.getMessage(thread_id,data)
setclient_id(SelectedThreadMessages.client_id)
setthreadMessage(SelectedThreadMessages.messages)
setTasks(SelectedThreadMessages.tasks)
if(SelectedThreadMessages.tasks){
  setHasTasks(true)
}else{
  setHasTasks(false)
}

  }catch(err){
    console.log(err)
  }
}




  useEffect(() => {
    fetchThreads();
  }, [selectedClient,searchKeywords, currentPage,dispatch]);


  useEffect(() => {
    if (selectedThreadId) {
      fetchThreadMessage(selectedThreadId)
    }
  
  }, [selectedThreadId]);




const handleRowClick = (thread) => {
  fetchThreads()


  setclient_id(thread.client.id)


setSelectedThreadId(thread.id);
setSubject(thread.subject);

const queryParams = new URLSearchParams({
      threadId: thread.id,
 });
navigate(`/chats?${queryParams.toString()}`);
  };


  const resetForm = () => {
    setSubject('');
    setNewMessage('');
    setSelectedClient('');

  };
  const messageChange = (e) => {
    setNewMessage(e.target.value);
  };

  const subjectChange = (e) => {
    setSubject(e.target.value);
  };

  const sendMessage = async (files) => {
    const data = {
       subject: subject,
      messageBody: NewMessage,
      client_id: selectedClientCompose,
      files:files.length>0?files:null
    }

    console.log(data)
   
    try {  

 await chatService.createNewThread(data)
  
      fetchThreads()
      files=[]
      setShow(false)
      resetForm()

    } catch (err) {
      console.log(err)
      resetForm()
    }
  }

  const changeSearch = (e) => {
    setsearchKeywords(e.target.value);
  };

  const handleSelectChange = (event) => {
   
if(event.target.value=="all"){
fetchThreads()
  setSelectedClient("")
}else{

  // setclient_id(event.target.value);
  setSelectedClient(event.target.value)
}
  };

  const handleSelectChangeCompose=(event)=>{
    setselectedClientCompose(event.target.value)
  }


 

const [index,setindex]=useState("")


const SendmessageFortask=async(index)=>{
  const data = {
    thread_id: selectedThreadId,
    messageBody:`${tasks[index].taskDetails} completed`
 };


  try {
    await chatService.SendNewMessage(selectedThreadId, data);

    fetchThreads()
 
    fetchThreadMessage(selectedThreadId)
  } catch (err) {
    console.log(err);
  }
}


  const handleTaskChange = async (index) => {


   
    setindex(index)
    const isTaskDone = tasks[index].taskDone;
    // Prevent unchecking once checked
    if (isTaskDone) {
      setShowModal(false)
      return;
    }

    setShowModal(true)
  };

  const checkedTask = async () => {

    const isTaskDone = tasks[index].taskDone;
  
    // Prevent unchecking once checked
    if (isTaskDone) {
      setShowModal(false);
      return;
    }
  
const data={
  taskIndex:index
}
 
    try {
      await chatService.updateTask(selectedThreadId, data);
      SendmessageFortask(index)
      fetchThreadMessage(selectedThreadId)
      setShowModal(false);
    } catch (err) {
      console.log(err);
    }
  };
  


  const handleCancel = () => {
    setShowModal(false);
 
  };

  const resetSelectedThread = () => {
    setSelectedThreadId(null);
  };




  useEffect(()=>{
if(NotificationThreadId!=undefined){
      setSelectedThreadId(NotificationThreadId)
  
    }
          },[NotificationThreadId!=undefined,NotificationThreadId])





        //   useEffect(() => {
        //     if (fetchNotificationBysocket == true) {
    
        //         fetchThreads()
           
        //         setfetchNotificationBysocket(false)
        //     }
        // }, [fetchNotificationBysocket == true])
    
        // socket.on('connects', () => {
        //     setfetchNotificationBysocket(true)
        //     console.log('Connected to socket.io server');
        // });
    
        // socket.on('notificationUpdate', () => {
        //     console.log('Notification update received:');
        //     setfetchNotificationBysocket(true)
        //     // Handle the notification update
        // });
    
        // socket.on('disconnects', () => {
        //     setfetchNotificationBysocket(false)
        //     // console.log('Disconnected from socket.io server');
        // });




  return (
    <Default pageTitle="Chats & Tasks" resetSelectedThread={resetSelectedThread} >
      <div className={`chats-wrapper card ${selectedThreadId ? 'with-thread-detials' : ''}`}>
        <div className={`chats ${selectedThreadId ? 'collapsed' : ''}`}>
          <ChatHeader
            unreadCount={unreadCount}
            clientDetails={clientDetails}
            handleSelectChange={handleSelectChange}
            searchKeywords={searchKeywords}
            changeSearch={changeSearch}
            handleShow={handleShow}
          />
          <div className='all-chats'>
            <ChatList
              threads={Threads}
              handleRowClick={handleRowClick}
             selectedThreadId={selectedThreadId}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            TotalPage={TotalPage}
            currentSet={currentSet}
            setCurrentSet={setCurrentSet}
            setcurrentPage={setcurrentPage}
          />
        </div>

        {selectedThreadId && (
          <>
            <ThreadDetails
              threadId={selectedThreadId}
              subject={subject}
              hasTasks={hasTasks}
              showTasks={showTasks}
              fetchThreads={fetchThreads}
              message={threadmessage}
              fetchThreadMessage={fetchThreadMessage}
              setShowTasks={setShowTasks}
              UserId={UserId}
              client_id={client_id}

            />
            {hasTasks && (
            <TaskList
              tasks={tasks}
              handleTaskChange={handleTaskChange}
              showTasks={showTasks}
             
            />
            )}
          </>
        )}

        <NewChatModal
          show={show}
          handleClose={handleClose}
          messageChange={messageChange}
          subjectChange={subjectChange}
          sendMessage={sendMessage}
          clientDetails={clientDetails}
          subject={subject}
          newmessage={NewMessage}
          selectedClientCompose={selectedClientCompose}
         
          handleSelectChangeCompose={handleSelectChangeCompose}
        />

<ConfirmationBox
        show={showModal}
        handleClose={handleCancel}
         handleConfirm={checkedTask}
      />
      </div>
    </Default>
  );
}

export default TaskAndChats;
