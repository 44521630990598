import React, { useEffect, useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Auth from '../../components/layouts/auth';
import authService from '../../services/forgetPassword.service';
import { validateEmail } from '../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../feature/message.slice';
import { setLoading } from '../../feature/loading.slice';
import messages from '../../utils/meesage';

const ForgotPassword = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const isLoading = useSelector((state) => state.loading.loading);
  const message= useSelector((state) => state.message.message);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const emailInputRef = useRef(null);
  const [formData, setFormData] = useState({
    email: email,
  });

  const [errors, setErrors] = useState({ email: '' });
  const [ApiError, setApiError] = useState(false);
  const [ApiSuccess, setApiSuccess] = useState(false);
  const [buttonClick, setbuttonClick] = useState(false);
  const [ApiErrorMessage, setApiErrorMessage] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));


  };

  const handleSignIn = async (event) => {
    event.preventDefault()

  const formErrors = validateEmail(formData.email); 
    setErrors(prevErrors => ({
      ...prevErrors,
      email: formErrors
    }));
    if (Object.keys(formErrors).length === 0) {
      try {
        dispatch(setLoading(true));
        dispatch(setMessage(messages.loading));
        const users = await authService.forgetPassword(formData);
        setbuttonClick(true);
        setApiSuccess(true);
        setApiErrorMessage(users.message);
      } catch (err) {
        setbuttonClick(true);
        setApiError(true);
        setApiErrorMessage(err.response.data);
      }finally {
        dispatch(setLoading(false));
  
      }
    }
  };


  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();  
    }
  }, []);
  const handleKeyDown = (event) => {
    console.log(event.key)
    if (event.key == 'Enter') {
      handleSignIn(event);
    }
  };
  return (
    <Auth buttonClick={buttonClick} ApiSuccess={ApiSuccess} ApiError={ApiError} ApiErrorMessage={ApiErrorMessage} pageTitle="Forgot Password">
      <Form  onSubmit={handleSignIn}  onKeyDown={handleKeyDown}>
            <Form.Group className="form-group">
              <div className="text-center mt-3">
                <Form.Label htmlFor="email">Email</Form.Label>
              </div>
              <Form.Control
               ref={emailInputRef}
                type="email"
                isInvalid={!!errors.email}
                id="email"
                aria-describedby="email"
                placeholder=" "
                value={formData.email}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
        <div className="text-center pt-3">
          {/* <Button className='btn-expanded'  type="submit" variant="btn btn-primary">Reset</Button> */}
          <Button className='btn-expanded'  type="submit" variant="btn btn-primary" disabled={isLoading}>{isLoading?(message):("Reset")}</Button>

        </div>
        <div className='text-center pt-3'>
          <Link to="/" className="secondary-cta">Back to Login</Link>
        </div>
      </Form>
    </Auth>
  );
};

export default ForgotPassword;
