import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';


import BankDetails from './sub-components/CompanyData_BankDetails';
import CompanyDataAddress from './sub-components/CompanyData_Addresses';
import CompanyDataContact from './sub-components/CompanyData_Contacts';
import CompanyDataLogin from './sub-components/CompanyData_Login';
import CompanyDataShareHolder from './sub-components/CompanyData_ShareHolder';
import CompanyDataTaxAccount from './sub-components/CompanyData_TaxAccount';
import CompanyDataDBA from './sub-components/CompanyData_DBA';


import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ClientService from '../../services/client.service';
import { setClientId } from '../../feature/client.slice';
import Default from '../../components/layouts/default';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; // Import the back arrow icon


const CompanyData = () => {
  const { id } = useParams();


  const navigate = useNavigate(); // Accessing navigate from React Router v6

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1); // This will take the user back one step in history
  };

  const [mainDetails, setmainDetails] = useState([])




  const fetchClient = async () => {

    try {

      const data = { clientId: id };

      const user = await ClientService.getclient(data)
      console.log(user)


      setmainDetails(user.clients)


    } catch (err) {
      console.log(err)
    }
  }





  useEffect(() => {

    fetchClient()


  }, [])





  return (

    <Default pageTitle="Company Data">
      <div className="company-details">
        <div className="back-link">
          <a href="#" onClick={handleBack} className="back-link-text">
            <FontAwesomeIcon icon={faArrowLeft} className="back-icon" /> Back to List
          </a>
        </div>
        {/* <p>{JSON.stringify(mainDetails, null, 2)}</p> */}

        {mainDetails[0] && (
          <Card className="details-card">
            <Card.Body className="card-body-content row">
              {/* Left side: Basic Info */}
              <div className="col-lg-6 basic-info">
                <h2 className="company-name">{mainDetails[0]?.name || '-'}</h2>

                {mainDetails[0]?.full_address && <div className="company-address">Address: {mainDetails[0].full_address}</div>}

                {mainDetails[0]?.ein && (
                  <div className="info-group">
                    <div className="label">Date of Formation:</div>
                    <div className="value">date here</div>
                  </div>
                )}


                {mainDetails[0]?.ein && (
                  <div className="info-group">
                    <div className="label">EIN:</div>
                    <div className="value">{mainDetails[0].ein}</div>
                  </div>
                )}

                {mainDetails[0]?.business_activity && (
                  <div className="info-group">
                    <div className="label">Business Activity:</div>
                    <div className="value">{mainDetails[0].business_activity}</div>
                  </div>
                )}

                {mainDetails[0]?.no_of_partners != null && (
                  <div className="info-group">
                    <div className="label">No of Owners:</div>
                    <div className="value">{mainDetails[0].no_of_partners}</div>
                  </div>
                )}

                {mainDetails[0]?.business_activity && (
                  <div className="info-group">
                    <div className="label">Registered Agent Details:</div>
                    <div className="value">Name: Full name here</div>
                    <div className="value">Address: full address here</div>
                  </div>
                )}
              </div>



              {/* Right side: Scrollable Additional Components */}
              <div className="col-lg-6 additional-info">
                {mainDetails[0].banks && <BankDetails banksDetails={mainDetails[0].banks} />}
                {mainDetails[0].Addresses && <CompanyDataAddress Address={mainDetails[0].Addresses} />}
                {mainDetails[0].contacts && <CompanyDataContact contacts={mainDetails[0].contacts} />}
                {mainDetails[0].shareholders && <CompanyDataShareHolder shareholders={mainDetails[0].shareholders} />}
                {mainDetails[0].tax_accounts && <CompanyDataTaxAccount tax_accounts={mainDetails[0].tax_accounts} />}
                {mainDetails[0].dba && <CompanyDataDBA dba={mainDetails[0].dba} />}
                {mainDetails[0].login && <CompanyDataLogin loginDetails={mainDetails[0].login} />}
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    </Default>



  )
}

export default CompanyData














