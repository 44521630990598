import React from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';

const ChatHeader = ({ unreadCount, clientDetails, handleSelectChange, searchKeywords, changeSearch, handleShow }) => {

  return (
  
    <div className="chats-header"   >
      <div className='container-fluid '>
        <div className='chats-header__left unread_count'>{unreadCount} unread messages</div>
        <div className='chats-header__right toolbar'>

          <div className='company-filter'>
            <select className="form-select form-select-sm" aria-label="Default select example" onChange={handleSelectChange}>
              <option selected disabled>By Corporation</option>
              <option value="all">All</option>

              {clientDetails.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>
          <div className='search'>
            <InputGroup className="shadow-none">
              <FormControl
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchKeywords}
                onChange={changeSearch}
                style={{ boxShadow: 'none' }}
                className='form-control form-control-sm'
              />

            </InputGroup>
          </div>
        </div>
        <div className='new-chat-btn'>
          <Button className="btn btn-sm" onClick={handleShow}>Compose</Button>
        </div>


      </div>
    </div>
  );
};

export default ChatHeader;
