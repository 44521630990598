import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf , faFileWord ,faFolder } from '@fortawesome/free-solid-svg-icons';
import { Table, InputGroup, FormControl, Badge } from 'react-bootstrap';
import { formatDateTime } from '../../utils/dateFormat';
import { trashIcon } from '../../utils/icons';
const Documents_files = ({files,handleDelete,uploadViewhandleShow}) => {



return (
    <div>
         <div className='table-responsive table-striped' >
                    <Table hover Table-striped >
                        <thead className='bg-dark'>
                            <tr className="">
                                <th className="text-secondary w-50 p-3 text-start " >Name</th>
                                <th className="text-secondary p-3 text-start ">Uploaded on</th>
                                <th className="text-secondary p-3 text-start ">Status</th>
                                <th className="text-secondary p-3 text-start ">action</th>

                            </tr>
                        </thead>
                        <tbody>


                         {files&&files.map((files)=>(
                            <tr className='text-start' >
                                <td className="customText fw-semibold p-3" >
                                      <FontAwesomeIcon icon={faFilePdf} classname="pe-2" style={{color: "#e66565", paddingRight:"10px" }} size=""  />
                                 <a href="" className='text-dark'>{files.filename}</a> </td>
                                <td className='customText p-3'>{formatDateTime(files.created_at)}</td>
                                <td className='p-3'><Badge pill bg='dark' className='fw-semibold' onClick={()=>uploadViewhandleShow(files)} >View & Approve</Badge></td>
                                <td className="action ">
                    {   files.uploaded_by=="clientportal_user"&&
                        <div>
                    <img src={trashIcon} alt="folder icon" onClick={()=>handleDelete(files.id)}/>
                  </div>}
                                

                                </td>
                            </tr>
                         ))  }
                          


                        </tbody>
                    </Table>
                </div>
    </div>
  )
}

export default Documents_files
