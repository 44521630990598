import React, { useEffect, useState } from "react";
import ClientService from "../../services/client.service";
import { useNavigate } from "react-router-dom";
import Default from "../../components/layouts/default";

const CompanyData_ClientsList = () => {

  const UserId = localStorage.getItem('userId');
  const [ClientDetails, setClientDetails] = useState([])
  console.log(ClientDetails)
  const clientDeatils = async () => {
    
    try {
      
      const user = await ClientService.getclient()
console.log(user)
      setClientDetails(user.clients)
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    clientDeatils()

  }, [])

  const navigate = useNavigate()
  const handlenavigate = (id) => {
    navigate(`/CompanyData/${id}`)
  }

  return (
    <Default pageTitle="Company Data">


<div className="ClientsList_container">
{ClientDetails.length > 0 ? (
      <>
        <p className="title py-2">You have {ClientDetails.length} corporations registered with us</p>
  <div className="card-container">
    
        {ClientDetails.map((Details) => (
          <div className="card" key={Details.id}>
            <div className="card-body">
            <h3><a onClick={() => handlenavigate(Details.id)}>{Details.name}</a></h3>
              <p>{Details.business_structure}</p>
              <div className="address">{Details.full_address}</div>
              <a className="links" onClick={() => handlenavigate(Details.id)}>
                See Details
              </a>
            </div>
          </div>
        ))}
      
   
  </div>
  </>
   ) : (
    <p>No corporations are registered with us. Please get in touch with our support team at teams@adm.com</p>
  )}
</div>


    </Default>
  );
};

export default CompanyData_ClientsList;
