import React, { useEffect, useState } from 'react'

import Modal from 'react-bootstrap/Modal';
import Cookies from 'js-cookie';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faComment } from '@fortawesome/free-solid-svg-icons';

import logo from "../../../assets/images/adm_logo.png"
import NotificationService from '../../../services/notification.service';

import { notificationIcon, chatIcon2, fileIcon } from '../../../utils/icons';
import io from 'socket.io-client';
import LoginAuth from '../../../services/auth.service';


// Connect to the socket.io server
// const socket = io(process.env.REACT_APP_API_URL);
const Header = () => {

    const [notificationshow, notiFicationsetShow] = useState(false);
    const [notifications, setnotifications] = useState([])



    const [userId, setuserId] = useState("")
    const [unreadCount, setUnreadCount] = useState("");
    // console.log( "Count",unreadCount)
    const [notificationType, setnotificationType] = useState("")
    // console.log(notificationType)

    const notificationhandleClose = () => notiFicationsetShow(false);
    const updateUnread = async () => {
        try {
            const updateunread = await NotificationService.updateUnread(userId)
            console.log(updateunread)
        } catch (err) {
            console.log(err)
        }
    }
    const notificationhandleShow = () => {
        notiFicationsetShow(true)
        updateUnread()
    };


    const [showPopup, setShowPopup] = useState(false);
    const setShowPopupclose = () => setShowPopup(false);
    const setShowPopupshow = () => setShowPopup(true);
    const [userName, setuserName] = useState("")
    const [surname, setsurname] = useState("")
    const [fetchNotificationBysocket, setfetchNotificationBysocket,] = useState(false)
    // console.log(fetchNotificationBysocket)
    useEffect(() => {
        const userName = localStorage.getItem('userName');
        const surname = localStorage.getItem('middleName');

        setuserName(userName)
        setsurname(surname)
    }, [])

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const navigate = useNavigate();
    const logout = async () => {
        await LoginAuth.logout();
        navigate("/")
    }


    const fetchNotification = async () => {
        const storedUserId = localStorage.getItem('userId');
        setuserId(storedUserId)
        try {
            const getNotification = await NotificationService.getNotifications()
            const models = notifications.map(notification => notification.model);
            setnotificationType(models.join(", "));
            setUnreadCount(getNotification.notifications.filter(notification => notification.unread == null).length);

            console.log(getNotification)
            setnotifications(getNotification.notifications)

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchNotification()

    }, [])

    // useEffect(() => {
    //     if (fetchNotificationBysocket == true) {

    //         fetchNotification()
    //         setfetchNotificationBysocket(false)
    //     }
    // }, [fetchNotificationBysocket])

    // socket.on('connects', () => {
    //     setfetchNotificationBysocket(true)
    //     console.log('Connected to socket.io server');
    // });

    // socket.on('notificationUpdate', () => {
    //     setfetchNotificationBysocket(true)
    //     console.log('Notification update received:');
    //     // Handle the notification update
    // });

    // socket.on('disconnects', () => {
    //     setfetchNotificationBysocket(false)
    //     // console.log('Disconnected from socket.io server');
    // });

    const getNotificationTitle = (model) => {
        switch (model) {
            case "ChatThread":
                return "MESSAGE";
            case "Task":
                return "DOCUMENT";
            default:
                return "UNKNOWN";
        }
    };


    const getTimeDifference = (lastMessageTime) => {
        const now = new Date();
        const lastMessageDate = new Date(lastMessageTime);
        const diffInMs = now - lastMessageDate;
        const diffInMinutes = Math.floor(diffInMs / 60000);

        if (diffInMinutes < 1) return 'Just now';
        if (diffInMinutes < 60) return `${diffInMinutes} mins ago`;

        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) return `${diffInHours} hours ago`;

        const diffInDays = Math.floor(diffInHours / 24);
        return `${diffInDays} days ago`;
    };



    const handleNotificationDetails = async (notifications) => {
        // console.log(notifications)
        const model = notifications.model
        const modelId = notifications.modelId
        notiFicationsetShow(false)
        if (model == "ChatThread") {
            navigate('/chats', { state: { NotificationThreadId: modelId } })
        } else if (model == "Task") {
            navigate('/chats', { state: { NotificationThreadId: modelId } })
        } else {
            navigate("/")
        }
    }
    const handleUpdateNotification=(event)=>{
        event.stopPropagation();
        // alert('hello')
        setUnreadCount("")
        updateUnread()
      }
    return (
        <>
            <div className="header">
                <div className="container-fluid">
                    <div className="notifications dropdown" onClick={handleUpdateNotification}>
                    

                        <button className="btn btn-link bell dropdown-toggle" id='notificationDropdown' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src={notificationIcon} alt="Notifications"  />
                            {unreadCount > 0 && <span className="notifications__counter">{unreadCount > 10 ? '10+' : unreadCount}</span>}
                        </button>

                        <ul className="dropdown-menu notifications-dropdown" aria-labelledby="notificationDropdown">
                            
                                <div className="card-body">
                                    <ul className="list-group list-group-flush">
                                        {notifications && notifications.map((notification, index) => (
                                            <li
                                                key={index}
                                                className="list-group-item"
                                                onClick={() => handleNotificationDetails(notification)}
                                            >
                                                <div className="notification-title">
                                                    {notification.title}
                                                </div>
                                                <div className="notification-content">
                                                    <div className='icon'>
                                                    {notification.model === "File" ? (
                                                        <img src={fileIcon}  />
                                                    ) : notification.model === "ChatThread" ? (
                                                        <img src={chatIcon2} />
                                                    ) : (
                                                        ""
                                                    )}
                                                    </div>
                                                    <div className="notification-message">
                                                    <div
          dangerouslySetInnerHTML={{
            __html: notification.message.length > 50
              ? `${notification.message.substring(0, 50)}...`
              : notification.message,
          }}
        />
                                                    </div>
                                                </div>
                                                <span className="notification-time">
                                                    By {notification.senderName} |{" "}
                                                    {getTimeDifference(notification.updatedAt)}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            
                        </ul>
                    </div>

                    <div className="myaccount-dropdown dropdown">
                    
                        <button className='btn btn-link user dropdown-toggle' id="userDropdown" type='button' data-bs-toggle='dropdown' data-bs-auto-close="true" aria-expanded='false'>

                        {`${userName} ${surname!=null?surname:""}`}

                       

                            </button>
                        <ul className="dropdown-menu" aria-labelledby="userDropdown">
                            
                            <li><a className="dropdown-item" href="#">Profile</a></li>
                            <li><a className="dropdown-item" href="#" onClick={logout}>Logout</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>



    )
}

export default Header