import React, { useState } from 'react';
import pdfIcon from "../../assets/images/pdf_icon.png"
import wordIcon from "../../assets/images/word_icon.png"
import PreviewFileModal from '../../components/common/Filemanager_PreviewFileModal';
import { useNavigate } from 'react-router-dom';


const ListCard = ({ title, items,files }) => {
  const navigate = useNavigate();

  const getIcon = (filetype) => {
    switch (filetype) {
      case 'pdf':
        return pdfIcon;
      case 'word':
        return wordIcon;
      default:
        return pdfIcon;
    } 
  };

  const [show, setShow] = useState(false);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (fileUrl) => {
   
    setSelectedFileUrl(fileUrl);

    setShow(true)
  };









  const handleSeeAllFiles = () => {
    navigate('/documents'); // Navigate to /documents
  };



  return (

    <div className="dashboard__card list-card">
       <div className="list-card__header d-flex justify-content-between align-content-center">
        <h3 className="list-card__title">{title}</h3>
        <button className="btn btn-sm btn-link mb-2" onClick={handleSeeAllFiles}>
          See All Files
        </button>
      </div>
      
      <ul className="list-card__list">
        {files.map((item, index) => (
          <li key={index} className={`list-card__item ${ item.icon && `has-icon`} `}>
             
             <div>
             
              <div className="list-card__description"  onClick={()=>handleShow(item.file_url)}>
              { item.file_type && (<span className='icon'><img src={getIcon(item.file_type)} className="icon me-2" /></span>)} 
                <div style={{textOverflow:"ellipsis", width:"90%"}} >{item.file_name}</div>
              </div>
              <span className="list-card__timestamp">{item.uploaded_on}</span>
              </div>
          </li>
        ))}
      </ul>
      <PreviewFileModal show={show} handleClose={handleClose} fileUrl={selectedFileUrl} />
    </div>
  );
};

export default ListCard;
