import axios from 'axios';
import Cookies from 'js-cookie';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/auth`;

const setAccessToken = (token) => {
    Cookies.set('token', token, { expires: 1 / 12 }); 
  };
  

const setUserDetails = (res) => {
    const id = res.data.user.user.id;
    const name = res.data.user.user.name;
    // const client = res.data.user.user.clients;
    const acesssToken = res.data.user.token.access.token;
    const refreshToken = res.data.user.token.refresh.token;
    const middleName = res.data.user.user.surname;
     localStorage.setItem("userName", name);
    localStorage.setItem("middleName", middleName);
    localStorage.setItem("userId", id);
    // localStorage.setItem("client", client);
    //  localStorage.setItem("token", acesssToken);
    localStorage.setItem("refreshToken",refreshToken);
 
  setAccessToken(acesssToken);
  };
  
  const login = async (data ) => {
   const res = await axios.post(API_URL + "/login",data)
    setUserDetails(res);
    console.log(res)
    return res;

} 


const logout = async ( ) => {
  localStorage.removeItem("userName");
  localStorage.removeItem("userId");
  localStorage.removeItem("client");
  // localStorage.removeItem("token");
  Cookies.remove('token');
  localStorage.removeItem("refreshToken");
   return Promise.resolve();

} 
 const RefreshToken = async (data ) => {
    const res = await axios.post(API_URL + "refreshToken",data)
     setUserDetails(res);
     return res;
 
 }
const LoginAuth={
    
    login,RefreshToken,logout

}

export default LoginAuth