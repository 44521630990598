import Cookies from 'js-cookie';
import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
const getAccessToken = () => {
  return Cookies.get('token');
};
const ProtectedRoutes = () => {
  const location = useLocation();
  const token = getAccessToken();
  const isAuthenticated = !!token
if (!isAuthenticated) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" />;
  }
if(location.pathname === '/'){
  return <Navigate to={`/dashboard`} />
}
  return <Outlet />;
};

export default ProtectedRoutes;
