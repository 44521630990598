import React from 'react';
import pdfIcon from "../../assets/images/pdf_icon.png"
import wordIcon from "../../assets/images/word_icon.png"


const ListCard = ({ title, items }) => {
  

  const getIcon = (filetype) => {
    switch (filetype) {
      case 'pdf':
        return pdfIcon;
      case 'word':
        return wordIcon;
      default:
        return pdfIcon;
    } 
  };

  return (

    <div className="dashboard__card list-card">
      <h3 className="list-card__title">{title}</h3>
      <ul className="list-card__list">
        {items.map((item, index) => (
          <li key={index} className={`list-card__item ${ item.icon && `has-icon`} `}>
             { item.icon && (<span className='icon'><img src={getIcon(item.filetype)} className="icon" /></span>)} 
             <div>
              <div className="card__description">
                {item.description}
              </div>
              <span className="list-card__timestamp">{item.timestamp}</span>
              </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ListCard;
