import React, { useEffect } from 'react'
import { Card, Accordion, } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Table, InputGroup, FormControl, Pagination, Container, Row, Col } from 'react-bootstrap';

import frame from '../../../assets/images/eye_icon.png'
import { useSelector } from 'react-redux';
import ShareholdersService from '../../../services/shareholder.service';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie } from '@fortawesome/free-solid-svg-icons'; // Import shareholders icon

export const CompanyDataShareHolder = ({ shareholders }) => {


    const [showAddpopup, setAddpopup] = useState(false);
    const handleClose = () => setAddpopup(false);
    const handleShow = (shareHolderDetails) => {

        setShareHolderAllDetails(shareHolderDetails)

        setAddpopup(true)
    };

    const [ShareHolderDetails, setShareHolderDetails] = useState([])
    // console.log(ShareHolderDetails)
    const [ShareHolderAllDetails, setShareHolderAllDetails] = useState([])
    const [Percentage, setPercentage] = useState([])


    // const clientId = useSelector((state) => state.client.clientId);
    // const fetchShareHolder = async () => {

    //     try {

    //         const ShareHolderDetails = await ShareholdersService.geShareHolder(clientId)

    //         setShareHolderDetails(ShareHolderDetails.shareholders)
    //         setPercentage(ShareHolderDetails.percentage)

    //         console.log(ShareHolderDetails)
    //     } catch (err) {
    //         console.log(err)
    //     }

    // }

    // useEffect(() => {
    //     fetchShareHolder()

    // }, [clientId])



    return (
        <>
            

            {shareholders.length != 0 &&

                <Card className="moreinfo-card">
                    <div>
                        <div className="d-flex justify-content-between py-1">
                            <h5 className="fw-bold card-title">
                                <FontAwesomeIcon icon={faUserTie} className="card-icon" /> Shareholders
                            </h5>
                        </div>
                        <div className="card-divider"></div>
                        {shareholders.length > 0 && shareholders.map((shareHolderDetails, index) => (
                            <div
                                className={`d-flex flex-wrap py-3 gap-2 card-details ${shareholders.length === 1 || index === shareholders.length - 1 ? 'no-border' : ''}`}
                                key={shareHolderDetails.full_name}
                            >
                                <div className="card-info half-width">
                                    <h6 className="mb-1">Full Name:</h6>
                                    <p className="text-black mb-0">{shareHolderDetails.full_name}</p>
                                </div>
                                <div className="card-info half-width">
                                    <h6 className="mb-1">SSN:</h6>
                                    <p className="text-black mb-0">{shareHolderDetails.ssn}</p>
                                </div>
                                <div className="card-info full-width">
                                    <h6 className="mb-0">Ownership Percentage:</h6>
                                    <div className="percentage-bar">
                                        <div className="progress">

                                            {/* Replace all % with actual percentage later */}
                                            <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p className="percentage-text">50%</p> {/* Replace with actual percentage later */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Card>

            }

            <Modal show={showAddpopup} onHide={handleClose} className='modal-lg'  >
                <Modal.Header closeButton>
                    <Modal.Title>Related Shareholders Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=" py-2 px-3">
                        <div className="row justify-content-start ">
                            <div className="col col-lg-4">
                                <span className='text-black' style={{ fontSize: '15px' }}>First Name</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{ShareHolderAllDetails.name}</p>
                            </div>
                            {ShareHolderAllDetails.middlename && <div className="col col-lg-4">
                                <span className='text-black' style={{ fontSize: '15px' }}>Middle Name</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{ShareHolderAllDetails.middlename}</p>
                            </div>}
                            {ShareHolderAllDetails.surname && <div className="col col-lg-4">
                                <span className='text-black' style={{ fontSize: '15px' }}>Last Name</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{ShareHolderAllDetails.surname}</p>
                            </div>}
                        </div>
                        <div className="d-flex justify-content-start mb-4">
                            <div className="w-50">
                                <span className='text-black' style={{ fontSize: '15px' }}>SSN</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ShareHolderAllDetails.ssn}</p>
                            </div>
                            <div className="w-50">
                                <span className='text-black' style={{ fontSize: '15px' }}>Address</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ShareHolderAllDetails.address}</p>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start mb-4">
                            <div className="w-50">
                                <span className='text-black' style={{ fontSize: '15px' }}>Percentage</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ShareHolderAllDetails.percentage}</p>
                            </div>
                            {ShareHolderAllDetails.dba != null && <div className="w-50">
                                <span className='text-black' style={{ fontSize: '15px' }}>DBA</span>
                                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ShareHolderAllDetails.dba}</p>
                            </div>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default CompanyDataShareHolder