import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import messages from '../../utils/meesage';
const NewChatModal = ({ show, handleClose, messageChange,clientDetails, subjectChange, sendMessage,handleSelectChangeCompose,subject,message,selectedClientCompose}) => {
  // console.log(subject)
  const isSendButtonDisabled = !subject || !message || !selectedClientCompose;
 
  const isLoading = useSelector((state) => state.loading.loading);
  const loadingmessage= useSelector((state) => state.message.message);
 
  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Send New Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div className='company-filter mb-3'>
            <select className="form-select form-select-sm" aria-label="Default select example" onChange={handleSelectChangeCompose}>
              <option selected disabled>By Corporation</option>
              <option value="all">All</option>

              {clientDetails.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>
      <Form.Floating className='mb-3'>
          
          <Form.Control type="text" onChange={subjectChange} />
          <Form.Label>Subject</Form.Label>
        </Form.Floating>
     

        <Form.Floating className='mb-3 '>
          <Form.Control rows={6} as="textarea" onChange={messageChange} style={{ height: '150px' }} />
          <Form.Label>Message</Form.Label>
        </Form.Floating>
  
        {/* <Form.Group className='mb-3'>
          <Form.Label>Attach Files</Form.Label>
          <Form.Control type="file" />
        </Form.Group> */}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" className='btn-sm' onClick={sendMessage}  disabled={isSendButtonDisabled}>Send Message</Button> */}
        <Button variant="primary" className='btn-sm' onClick={sendMessage}  disabled={isSendButtonDisabled ||isLoading }> {isLoading?(loadingmessage):("Send Message")}</Button>

      </Modal.Footer>
    </Modal>
  );
};

export default NewChatModal;
